import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { apis } from 'src/environments/environment'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/admin`

    constructor(public http: HttpClient) { }

    getList(params: any): Observable<any> {
        const url = `${this.baseUrl}/report/parallel-inspections`
        return this.http.post<any>(url, params)
    }

    getParallelList(): Observable<any> {
        const url = `${apis.baseUrl}/admin/dropdown/parallels`
        return this.http.get<any>(url, {})
    }


    downloadExcelFile(params: any): Observable<any> {
        const options: any = { reportProgress: true, observe: 'events', responseType: 'blob' }
        const url = `${this.baseUrl}/report/parallel-inspections/download`
        return this.http.post<any>(url, params, options)
    }
}
